<template>
  <router-link :to="href">
    <div class="result-item">
      <h4 class="title--xs">{{ title }}</h4>
      <p class="text">{{ desc }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'Search Result Item',
  props: {
    title: String,
    desc: String,
    href: String
  }
};
</script>

<style scoped>
.result-item {
  width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
  margin: 0 auto;
  margin-bottom: 25px;
  box-shadow: 0px 2px 20px rgba(174, 174, 174, 0.1);
}
.result-item .title--xs {
  margin-right: 0;
  padding-bottom: 25px;
  text-transform: capitalize;
}
@media screen and (min-width: 320px) and (max-width: 650px) {
  .result-item {
    max-width: 100%;
  }
}
</style>
