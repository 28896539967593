<template>
  <div class="about-grid">
    <div class="about-grid__left">
      <h2 class="title fs64 pb40">{{ title }}</h2>
      <TeamPayLinkButton
        :title="$t('about.grid.linkText')"
        :to="{ name: 'Branches' }"
      />
    </div>
    <div class="about-grid__right">
      <div class="about-grid__items">
        <img
          src="@/assets/img/images/grid-item-1.jpg"
          alt=""
          class="about-grid-item item-1"
        />
        <img
          src="@/assets/img/images/grid-item-2.jpg"
          alt=""
          class="about-grid-item item-2"
        />
        <img
          src="@/assets/img/images/grid-item-3.jpg"
          alt=""
          class="about-grid-item item-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TeamPayLinkButton from '../UI/TeamPayLinkButton.vue';
export default {
  name: 'About Waiting You',
  props: {
    title: String
  },
  components: { TeamPayLinkButton }
};
</script>

<style>
.about-grid {
  padding-top: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1020px;
  margin: 0 auto;
  gap: 70px;
}
.about-grid__left {
  max-width: 425px;
}
.about-grid__right {
  position: relative;
}

.about-grid__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 2fr);
  grid-column-gap: 16px;
  align-items: center;
}

.item-1 {
  grid-area: 1 / 1 / 2 / 2;
  height: 246px;
}
.item-2 {
  grid-area: 2 / 1 / 3 / 2;
  height: 280px;
}
.item-3 {
  grid-area: 1 / 2 / 3 / 3;
  align-self: center;
}
@media screen and (min-width: 320px) and (max-width: 650px) {
  .about-grid {
    flex-direction: column;
    max-width: 100%;
    padding-top: 50px;
  }
  .about-grid__left {
    max-width: 100%;
  }
  .about-grid .title {
    text-align: center;
  }
  .about-grid__right {
    max-width: 100%;
    overflow: hidden;
  }

  .item-1 {
    grid-area: 1 / 1 / 2 / 2;
    max-height: 161px;
    max-width: 153px;
  }
  .item-2 {
    grid-area: 2 / 1 / 3 / 2;
    max-height: 183px;
    max-width: 153px;
  }
  .item-3 {
    grid-area: 1 / 2 / 3 / 3;
    max-height: 290px;
    max-width: 148px;
    align-self: center;
  }

  .about-grid .btn {
    margin: 0 auto;
  }
}
@media screen and (min-width: 651px) and (max-width: 1024px) {
  .about-grid {
    gap: 50px;
  }
  .item-1 {
    grid-area: 1 / 1 / 2 / 2;
    max-height: 161px;
    max-width: 153px;
  }
  .item-2 {
    grid-area: 2 / 1 / 3 / 2;
    max-height: 183px;
    max-width: 153px;
  }
  .item-3 {
    grid-area: 1 / 2 / 3 / 3;
    max-height: 290px;
    max-width: 148px;
    align-self: center;
  }
}
</style>
