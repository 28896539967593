<template>
  <div class="about__features">
    <div class="about__left">
      <h3 class="title fs32">{{ title }}</h3>
      <ul class="about__features-list">
        <li
          class="about__features-item"
          v-for="(f, idx) in features"
          :key="idx"
        >
          {{ $t(f.key) }}
        </li>
      </ul>
    </div>
    <div class="about__right">
      <img
        class="about__img"
        src="@/assets/img/images/about-us-fetures.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutFeatures',
  props: {
    title: String,
    features: Array
  }
};
</script>

<style scoped>
.about__features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  max-width: 1150px;
  width: 1150px;
  margin: 0 auto;
}
.about__features-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about__features-item {
  position: relative;
  padding-left: 23px;
}
.about__features-item::before {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  background-color: #fecc00;
}

.about__features .about__img {
  max-width: 415px;
  max-height: 450px;
  margin-left: 35px;
}

@media screen and (min-width: 320px) and (max-width: 650px) {
  .about__features {
    flex-direction: column;
    max-width: 100%;
  }

  .about__features-list {
    width: 80%;
    margin: 0 auto;
  }
  .about__features .about__img {
    margin: 0 auto;
    max-width: 315px;
    max-height: 350px;
  }
}
@media screen and (min-width: 651px) and (max-width: 1080px) {
  .about__features {
    flex-direction: row;
    max-width: 100%;
  }
  .about__features .about__img {
    max-width: 315px;
    max-height: 350px;
    margin: 0 auto;
  }
}
</style>
