<template>
  <main style="padding-top: 90px">
    <section class="about">
      <div class="container">
        <div class="about__inner">
          <div class="heading">
            <div class="about__info">
              <h1 class="title fs64 pb30 m0a">{{ $t('about.head.title') }}</h1>
              <p class="desc fs16 m0a">
                {{ $t('about.head.body') }}
              </p>
            </div>
          </div>
          <AboutFeatures
            :features="features"
            :title="$t('about.features.title')"
          />
          <AboutServices
            :innerTitle="$t('about.services.innerTitle')"
            :innerText="$t('about.services.innerText')"
          />
          <AboutGrid :title="$t('about.grid.title')" />
        </div>
      </div>
      <p class="updated">{{ $t('updated') }} 28.02.2022</p>
    </section>
  </main>
</template>

<script>
import AboutFeatures from '@/components/AboutUsInfo/AboutFeatures.vue';
import AboutServices from '@/components/AboutUsInfo/AboutServices.vue';
import AboutGrid from '@/components/AboutUsInfo/AboutGrid.vue';

export default {
  name: 'About',
  components: { AboutFeatures, AboutServices, AboutGrid },
  metaInfo() {
    return {
      title: this.$t('meta.pages.aboutUs.title') + ' | TeamPay',
      description: this.$t('meta.pages.aboutUs.description')
    };
  },
  data() {
    return {
      features: [
        { key: 'about.features.list.item1' },
        { key: 'about.features.list.item2' },
        { key: 'about.features.list.item3' }
      ],
      findUsList: [
        { key: 'about.whereToFind.list.item1' },
        { key: 'about.whereToFind.list.item2' },
        { key: 'about.whereToFind.list.item3' }
      ]
    };
  }
};
</script>

<style>
.about__inner {
  display: flex;
  flex-direction: column;
}

.about__info {
  max-width: 625px;
  margin: 0 auto;
}
</style>
