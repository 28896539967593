<template>
  <main style="padding-top: 90px">
    <section class="information general">
      <div class="container--xl">
        <div class="information__inner">
          <h1 class="title fs64 tc pb50 m0a">{{ $t('general.head.title') }}</h1>
          <div class="information__container">
            <div class="information__header">
              <div class="information__tab-list">
                <carousel
                  :breakpoints="breakpoints"
                  ref="carousel"
                  :snapAlign="start"
                >
                  <slide
                    class="slider__item"
                    v-for="tab in tabs"
                    :key="tab.id"
                    :class="{
                      'carousel__slide--visible': tab.id === activeTab
                    }"
                  >
                    <div class="information__tab-item">
                      <a
                        :href="`#${activeTab}`"
                        class="information__tab-link"
                        :class="{
                          active: tab.id === currentHash
                        }"
                        @click="selectTab(tab.id)"
                      >
                        {{ $t(tab.name) }}
                      </a>
                    </div>
                  </slide>
                  <template #addons="{ slidesCount }">
                    <navigation v-if="slidesCount > 5" />
                  </template>
                </carousel>
              </div>
            </div>
            <div class="information__content">
              <GeneralDocs
                v-if="currentHash === '1'"
                :generalTitle="$t('general.docs.generalTitle')"
                :yearlyTitle="$t('general.docs.yearlyTitle')"
              />
              <Owners
                v-if="currentHash === '2'"
                :title="$t('general.owners.title')"
                :desc="$t('general.owners.body')"
              />
              <Reports
                v-if="currentHash === '3'"
                :mainTitle="$t('general.reports.mainTitle')"
              />
              <Regulations
                v-if="currentHash === '4'"
                :title="$t('general.regulations.title')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import GeneralDocs from '@/components/GeneralInformationTabs/GeneralDocs.vue';
import Owners from '@/components/GeneralInformationTabs/Owners.vue';
import Regulations from '@/components/GeneralInformationTabs/Regulations.vue';
import Reports from '@/components/GeneralInformationTabs/Reports.vue';
export default {
  name: 'General Info',
  components: {
    Carousel,
    Slide,
    Navigation,
    GeneralDocs,
    Owners,
    Regulations,
    Reports
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.generalInformation.title') + ' | TeamPay'
    };
  },
  data() {
    return {
      tabs: [
        {
          id: '1',
          name: 'general.tabs.tab1'
        },
        {
          id: '2',
          name: 'general.tabs.tab2'
        },
        {
          id: '3',
          name: 'general.tabs.tab3'
        },
        {
          id: '4',
          name: 'general.tabs.tab4'
        }
      ],
      breakpoints: {
        320: {
          itemsToShow: 2
        },
        550: {
          itemsToShow: 3
        },
        768: {
          itemsToShow: 5
        }
      },
      activeTab: '1',
      currentHash: window.location.hash.charAt(1)
    };
  },
  methods: {
    selectTab(id) {
      this.activeTab = id;
      this.currentHash = id;
      this.$refs.carousel.slideTo(this.currentHash - 1);
    },
    mountSlide() {
      this.$refs.carousel.slideTo(this.currentHash - 1);
    }
  },
  mounted() {
    this.$route.path = this.$route.path + window.location.hash;
    this.activeTab = window.location.hash.charAt(1);
    this.mountSlide();
  },
  updated() {
    this.$route.path = this.$route.path + window.location.hash;
    this.currentHash = window.location.hash.charAt(1);
    this.mountSlide();
  }
};
</script>

<style>
.general .carousel {
  width: 100%;
}
</style>
