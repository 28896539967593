<template>
  <div class="main-slider">
    <carousel :items-to-show="1">
      <slide class="slider__item">
        <video class="slider__video" autoplay loop muted playsinline>
          <source src="../../assets/img/video/coin.mp4" type="video/mp4" />
        </video>
      </slide>
    </carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  }
};
</script>

<style>
.main-slider {
  max-width: 700px;
  border-radius: 20px;
}
.main-slider .carousel {
  width: 700px;
  border-radius: 20px;
}
.main-slider .carousel__pagination-button {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--teamCyan);
}
.main-slider .carousel__pagination-button--active {
  background-color: var(--teamBlue);
}
.main-slider .carousel__prev,
.main-slider .carousel__next {
  width: 46px;
  height: 46px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--teamLightGray);
}
.main-slider .carousel__icon {
  fill: var(--teamDarkGray);
  width: 40px;
  height: 40px;
}
.main-slider .slider__item {
  width: 700px;
  height: 450px;
  border-radius: 20px;
}

.main-slider .slider__video {
  max-width: 700px;
  max-height: 450px;
  object-fit: cover;
  border-radius: 20px;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .main-slider {
    max-width: 100%;
  }
  .main-slider .carousel {
    max-width: 100%;
  }
  .main-slider .carousel__prev,
  .main-slider .carousel__next {
    display: none;
  }
  .main-slider .slider__item {
    width: 350px;
    height: 220px;
  }
  .main-slider .slider__video {
    object-fit: cover;
    border-radius: 20px;
    max-width: 100%;
  }
}
@media screen and (min-width: 551px) and (max-width: 900px) {
  .main-slider {
    max-width: 100%;
  }

  .main-slider .carousel {
    max-width: 100%;
  }

  .main-slider .slider__video {
    object-fit: cover;
    border-radius: 20px;
    max-width: 100%;
  }
}
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .main-slider {
    max-width: 100%;
  }
  .main-slider .carousel {
    max-width: 100%;
  }
  .main-slider .slider__video {
    object-fit: cover;
    border-radius: 20px;
    max-width: 100%;
  }
}
</style>
