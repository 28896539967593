<template>
  <div class="functions">
    <div class="container">
      <div class="functions__inner">
        <div class="functions__info">
          <h2 class="title fs32 pb30">{{ title }}</h2>
          <p class="desc fs16">
            {{ desc }}
          </p>
        </div>
        <Slider class="slider" />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from './Slider.vue';

export default {
  name: 'How It Works',
  props: {
    title: String,
    desc: String
  },
  components: {Slider}
};
</script>

<style scoped>
.functions {
  padding-top: 32px;
}
.functions__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.functions__info {
  max-width: 400px;
}
.title--m {
  padding-bottom: 20px;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .functions__inner {
    flex-direction: column;
  }
  .functions__info {
    text-align: center;
    padding-bottom: 34px;
  }
}
@media screen and (min-width: 551px) and (max-width: 900px) {
  .functions__inner {
    flex-direction: column;
  }
  .functions__info {
    text-align: center;
    padding-bottom: 34px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .functions__inner {
    flex-direction: row;
  }
  .functions__info {
    max-width: 50%;
  }
  .slider {
    max-width: 50%;
  }
}
</style>
