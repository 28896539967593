<template>
  <div class="contact__info">
    <div class="contact__info-top">
      <div class="contact__info-texts">
        <span class="contact__info-title">{{ $t('contact.info.title') }}</span>
        <ul class="contact__info-list">
          <li class="contact__info-item">
            <img
              src="@/assets/img/icons/phone-dark.svg"
              alt=""
              class="contact__info-icon"
            />
            <a href="tel:+37410300003" class="contact__info-link">
              <span class="contact__info-type">{{
                $t('contact.info.title')
              }}</span>
              <span class="contact__info-text">+374 10 300 003</span>
            </a>
          </li>
          <li class="contact__info-item">
            <img
              src="@/assets/img/icons/email-dark.svg"
              alt=""
              class="contact__info-icon"
            />
            <a href="mailto:info@teampay.am" class="contact__info-link">
              <span class="contact__info-type">{{
                $t('contact.info.email')
              }}</span>
              <span class="contact__info-text">info@teampay.am </span>
            </a>
          </li>
          <li class="contact__info-item">
            <img
              src="@/assets/img/icons/location-dark.svg"
              alt=""
              class="contact__info-icon"
            />
            <p class="contact__info-text">
              <span class="contact__info-type">{{
                $t('contact.info.hq')
              }}</span>
              <span class="contact__info-text">{{
                $t('contact.info.address')
              }}</span>
            </p>
          </li>
          <li class="contact__info-item">
            <img
              src="@/assets/img/icons/time.svg"
              alt=""
              class="contact__info-icon"
            />
            <p class="contact__info-text">
              <span class="contact__info-type">{{
                $t('contact.info.hours')
              }}</span>
              <span class="contact__info-text">{{
                $t('contact.info.days')
              }}</span>
            </p>
          </li>
        </ul>
      </div>
      <div class="contact__image">
        <img src="@/assets/img/images/contact_coin.png" alt="" />
      </div>
    </div>
    <div class="contact__social">
      <span class="contact__social-title">{{ $t('contact.info.findUs') }}</span>
      <ul class="contact__social-list">
        <li
          class="contact__social-item"
          v-for="logo in social"
          :key="logo.name"
        >
          <a :href="logo.href" class="contact__social-link" target="_blank"
            ><img :src="logo.logo" alt="" class="contact__social-icon"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact Us Info',
  data() {
    return {
      social: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/Teampay.am',
          logo: require('../../assets/img/icons/facebook.svg')
        }
      ]
    };
  }
};
</script>

<style scoped>
.contact__info {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 40px;
}
.contact__info-top {
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
}
.contact__image {
  max-width: 100px;
}
.contact__image img {
  width: 100%;
}
.contact__info-title {
  font-weight: bold;
  display: inline-block;
  padding-bottom: 42px;
  font-size: 22px;
}
.contact__info-item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.contact__info-icon {
  margin-right: 27px;
}
.contact__info-link {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
.contact__info-link:hover {
  text-decoration: underline;
}
.contact__info-text {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
.contact__info-type {
  font-weight: bold;
  padding-bottom: 5px;
}
.contact__social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact__social-title {
  font-weight: bold;
  padding-bottom: 22px;
  display: inline-block;
}
.contact__social-list {
  display: flex;
  align-items: center;
  gap: 16px;
}
.contact__social-icon {
  width: 32px;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .contact__info {
    padding: 23px 16px;
  }
  .contact__info-top {
    margin-bottom: 40px;
  }
  .contact__info-title {
    font-weight: bold;
    display: inline-block;
    padding-bottom: 42px;
    font-size: 18px;
  }
  .contact__info-link {
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }
  .contact__info-text {
    font-size: 15px;
  }
  .contact__image {
    max-width: 50px;
  }
}
</style>
