<template>
  <div class="documents">
    <div class="container">
      <div class="documents__inner">
        <div class="documents__box">
          <h2 class="title fs24 tl pb45">{{ generalTitle }}</h2>
        </div>
        <div class="documents__box">
          <div class="files">
            <ul class="files__list">
              <li class="files__list-item" v-for="doc in links" :key="doc.id">
                <img
                  class="files__list-icon"
                  src="@/assets/img/utils/file-icon.svg"
                  alt=""
                />
                <a :href="doc.link" class="files__list-text" target="_blank">{{
                  doc.title
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="updated">{{ $t('updated') }} 28.02.2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Documents',
  props: {
    generalTitle: String,
    yearlyTitle: String,
    desc: String
  },
  data() {
    return {
      links: [
        {
          id: '1',
          title:
            'Վճարահաշվարկային համակարգերի և վճարահաշվարկային կազմակերպությունների մասին ՀՀ օրենք',
          link: 'http://www.arlis.am/DocumentView.aspx?DocID=110855'
        },
        {
          id: '2',
          title:
            'Կանոնակարգ 17/01 «Վճարահաշվարկային կազմակերպությունների լիցենզավորումը, մասնաճյուղերի հաշվառումը, վճարահաշվարկային կազմակերպությունների ղեկավարների որակավորումը, վճարահաշվարկային կազմակերպությունների գործունեության վայրին ներկայացվող տեխնիկական հագեցվածության, տարածքային, ծրագրային, անվտանգության պահանջները, ինչպես նաեւ վճարահաշվարկային կազմակերպությունների լիցենզիաների գրանցամատյանի ձեւը, վարման կարգը, դրանում ներառվող տեղեկությունները»  ',
          link: 'https://www.cba.am/AM/laregulations/Regulation_17.01.pdf'
        },
        {
          id: '3',
          title:
            'Կանոնակարգ 17/02 «Վճարահաշվարկային կազմակերպություների գործունեության կարգավորումը, վճարահաշվարկային կազմակերպությունների համար սահմանվող տնեսական նորմատիվները, դրանց հաշվարկման կարգը» ',
          link: 'https://www.cba.am/AM/laregulations/Regulation_17.02.pdf'
        },
        {
          id: '4',
          title:
            'Կանոնակարգ 17/03 «Վճարահաշվարկային կազմակերպությունների ֆինանսական եւ այլ հաշվետվությունները, դրանց ներկայացումը եւ հրապարակումը»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_17.03.zip'
        },
        {
          id: '5',
          title:
            'Կանոնակարգ 17/09 «Հայաստանի Հանրապետության տարածքում գործող վճարահաշվարկային կազմակերպություններում կանխիկ դրամով կատարվող գործառնությունները»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_17.09.pdf'
        },
        {
          id: '6',
          title:
            'Կանոնակարգ 8/03. «Բանկերի, վարկային կազմակերպությունների, ապահովագրական ընկերությունների, ապահովագրական բրոքերների, ներդրումային ընկերությունների,ներդրումային ֆոնդերի կառավարիչների, կենտրոնական դեպոզիտարիայի և դրամական փոխանցումներ իրականացնող վճարահաշվարկային կազմակերպություններիկողմից տեղեկությունների հրապարակումը»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_8.03.rar'
        },
        {
          id: '7',
          title:
            'Կանոնակարգ 8/04. «Հաճախորդների բողոք պահանջների քննության գործընթացը կարգավորող ներքին իրավական ակտերին ներկայացվող նվազագույն պայմանները և սկզբունքները»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_8.04.pdf'
        },
        {
          id: '8',
          title:
            'Կանոնակարգ 18.«Հայաստանյան վճարահաշվարկային համակարգերի ստեղծման և գործունեության իրականացման համար թույլտվության տրամադրումը»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_18.pdf'
        },
        {
          id: '9',
          title:
            'Կանոնակարգ 19. «Արտասահմանյան վճարահաշվարկային համակարգերում մասնակցելու թույլտվության տրամադրումը, արտասահմանյան վճարահաշվարկային համակարգի գործունեության կանոններում և ներկայացված փաստաթղթերում կենտրոնական բանկին տեղեկացման ենթակա փոփոխությունների ցանկը և համակարգը բնութագրող չափանիշները, գրանցամատյանի վարման կարգը»',
          link: 'https://www.cba.am/AM/laregulations/Regulation_19.pdf'
        },
        {
          id: '10',
          title:
            'Վճարման հանձնարարականով միջոցների փոխանցումների մասին ՀՀ օրենք',
          link: 'https://www.arlis.am/DocumentView.aspx?DocID=373'
        },
        {
          id: '11',
          title:
            'ՀՀ օրենքը փողերի լվացման և ահաբեկչության ֆինանսավորման դեմ պայքարի մասին',
          link: 'https://www.arlis.am/documentview.aspx?docID=93142'
        },
        {
          id: '12',
          title: 'ՀՀ քաղաքացիական օրենսգիրք',
          link: 'https://www.arlis.am/documentview.aspx?docid=74658'
        }
      ]
    };
  }
};
</script>

<style></style>
